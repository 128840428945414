import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Box from 'components/box';
import Head from 'components/head';
import Header from 'components/header';
import Gallery from 'components/gallery';

const References = ({ data }) => {
  const footnotes = [data.referencesJson.disclaimer];
  return (
    <Layout footnotes={footnotes}>
      <Head pageTitle={data.referencesJson.title} />
      <Box>
        <Header mb={0.1} mt={1} tag="h2" apex={1}>
          {data.referencesJson.title}
        </Header>
      </Box>
      <Gallery items={data.referencesJson.gallery} />
    </Layout>
  );
};

References.propTypes = {
  data: PropTypes.object.isRequired,
};

export default References;

export const query = graphql`
  query ReferencesQuery {
    referencesJson {
      title
      disclaimer
      gallery {
        title
        copy
        image {
          childImageSharp {
            fluid(maxHeight: 500, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
